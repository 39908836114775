// Aggregator News

// entry
// agg-news-element
// title
// meta
// body
// action (optional)
@mixin clearfix{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
}
@function aspect-to-percent($target, $container){
  @return ($container / $target) * 100%;
}

$agg-news-element-entry-title-font: $font-primary;
$agg-news-element-entry-title-size: 24px;
$agg-news-element-entry-title-weight: bold;
$agg-news-element-entry-title-line-height: 1.25;
$agg-news-element-entry-title-color: $color-primary;
$agg-news-element-entry-title-color-hover: $color-primary;
$agg-news-element-entry-title-case: none;

$agg-news-element-entry-meta-font: $font-primary;
$agg-news-element-entry-meta-size: 12px;
$agg-news-element-entry-meta-weight: normal;
$agg-news-element-entry-meta-line-height: 1.2;
$agg-news-element-entry-meta-color: $color-black;
$agg-news-element-entry-meta-case: none;
$agg-news-element-entry-meta-padding: 5px 0px 0px 0px;

$agg-news-element-entry-body-font: $font-primary;
$agg-news-element-entry-body-size: 16px;
$agg-news-element-entry-body-weight: normal;
$agg-news-element-entry-body-line-height: 1.5;
$agg-news-element-entry-body-color: $color-gray-dk;
$agg-news-element-entry-body-case: none;
$agg-news-element-entry-body-padding: 5px 0px 0px 0px;

$agg-news-element-entry-preview-image-width: 160px;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(4, 3);
$agg-news-element-entry-preview-image-margin: 0 10px 10px 0;
$agg-news-element-entry-preview-image-float: true;

$agg-news-element-entry-button-padding: 10px 0px 0px 0px;

$agg-news-element-entry-padding-y: 20px;

// Custom Variables
$agg-news-custom-entry-title-font: $color-gray-dk;

$agg-news-custom-entry-card-title-size: 16px;

$agg-news-custom-entry-body-weight: bold;
$agg-news-custom-entry-body-color: $color-black;

$agg-news-custom-entry-preview-image-width: 100%;
$agg-news-custom-entry-preview-large-image-width: 67%;
$agg-news-custom-entry-preview-image-aspect-ratio: aspect-to-percent(2, 1);
$agg-news-custom-entry-preview-image-margin: 0 20px 0 0;

$agg-news-custom-entry-padding: 20px;
$agg-news-custom-entry-background-color: #fff;


$agg-news-custom-entry-border: 1px solid $color-gray-lt;

// RESETS
.newsAggregatorElement{
  .condensed ul{
    list-style: none;
    li:empty{ display: none; }
  } 
}

// Element Header
// .newsAggregatorElement{
//   .aggHeader{
//     @include global-element-header;
//   }
// }

// List Styles
.newsAggregatorElement{
  .condensed,
  .expanded{
    border-bottom: $global-ui-border-size solid $global-ui-border-color;
    padding: $agg-news-element-entry-padding-y 0px;
  }
  .aggHeader + .condensed,
  .aggHeader + .expanded {
    margin-top: 15px;
    border-top: $global-ui-border-size solid $global-ui-border-color;
  
  }
}  


.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .condensed > a{
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  // ENTRY TITLE
  .condensed .details h4 a{
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .condensed .dateAuthor{
    line-height: 1.2;
    padding: $agg-news-element-entry-meta-padding;
  }
  .condensed .newsAuthor,
  .condensed .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .condensed .teaser{
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
  }

  // ENTRY BUTTON
  .condensed .commentsAndMore{
    padding: $agg-news-element-entry-button-padding;
    .button-content {
      // @include styled-link-button;
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .condensed .readMore .button-small{
    @include global-link-style;
    @include global-link-container;
    @include global-link-text($align: center);
    display: inline-block;
    
  }
}


// EXPANDED
.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    &:hover{
      color: $agg-news-element-entry-title-color-hover;
    }
  }

  // ENTRY META
  .expanded .dateAuthor{
    line-height: 1.2;
    padding: $agg-news-element-entry-meta-padding;
  }
  .expanded .newsAuthor,
  .expanded .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .expanded .newsItemElement > h4 {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
  }

  // ENTRY BUTTON
  .expanded .commentsAndMore{
    margin: 0;
    .commentCount{
      padding: $agg-news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content{
      // @include styled-link-button;
      display: inline-block;     
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .expanded .readMore .button-small{
    @include global-link-style;
    @include global-link-container;
    @include global-link-text($align: center);
    display: inline-block;
    
  }
}

// 8. Custom styles
.featured-news {
  .condensed {
    border: none !important;
    padding: $agg-news-custom-entry-padding;
    box-sizing: content-box;
    background-color: $agg-news-custom-entry-background-color;
    display: flex !important;
    align-items: flex-end;
    @media screen and (max-width: 1024px){
      display: block !important;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      display: block !important;
    }
  }
  .condensed .details h4 a {
    color: $agg-news-custom-entry-title-font;
  }
  .condensed > a{
    max-width: $agg-news-custom-entry-preview-large-image-width;
    margin: $agg-news-custom-entry-preview-image-margin;
    flex-shrink: 0;
    @media screen and (max-width: 1024px){
      max-width: 100%;
      margin-bottom: 10px;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      max-width: 100%;
      margin-bottom: 10px;
    }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-custom-entry-preview-image-aspect-ratio;
    }
  }
  .condensed .teaser {
    font-size: 16px;
    color: $agg-news-custom-entry-body-color;
    font-weight: $agg-news-custom-entry-body-weight;
    padding-top: 20px;
  }
  .details {
    display: flex;
    flex-flow: column;
    @media screen and (max-width: 1024px){
      display: block;
    }
    @media screen and (max-width: $breakpoint-sm-max){
      display: block;
    }
    .dateAuthor {
      order: 1;
      .newsAuthor {
        display: none;
      }
      .newsDate {
        font-size: 10px;
        font-weight: normal;
        color: $color-gray-md;
        padding-top: 20px;
      }
    }
  }
  .readMore {
    display: none;
  }
}

.news-card {
  .condensed {
    border: none;
    padding: $agg-news-custom-entry-padding;
    background-color: $agg-news-custom-entry-background-color;
  }
  .condensed .details h4 a {
    color: $agg-news-custom-entry-title-font;
    font-size: $agg-news-custom-entry-card-title-size;
  }
  .condensed > a {
    max-width: $agg-news-custom-entry-preview-image-width;
    float: none;
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-custom-entry-preview-image-aspect-ratio;
    }
  }
  .details {
    display: flex;
    flex-flow: column;
    .dateAuthor {
      order: 1;
      .newsAuthor {
        display: none;
      }
      .newsDate {
        font-size: 10px;
        font-weight: normal;
        color: $color-gray-md;
        padding-top: 20px;
      }
    }
  }
  .condensed .teaser {
    font-size: 14px;
    color: $agg-news-custom-entry-body-color;
    font-weight: $agg-news-custom-entry-body-weight;
    padding-top: 20px;
  }
  .readMore {
    display: none;
  }
}

.custom-tag {
  padding-bottom: 20px;
  span {
    background: $color-primary;
    color: #fff;
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: bold;
    font-size: 11px;
    padding: 5px 15px;
  }
}
