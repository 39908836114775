// Number
$custom-pp-number-font-family: $font-primary;
$custom-pp-number-text-size: 64px;
$custom-pp-number-text-size-md: 36px;
$custom-pp-number-text-weight: 900;
$custom-pp-number-text-color: white;

// Name
$custom-pp-name-font-family: $font-primary;
$custom-pp-name-text-size: 48px; 
$custom-pp-name-text-size-md: 36px;
$custom-pp-name-text-weight: 900;
$custom-pp-name-text-color: white;

// Stats
$custom-pp-stats-font-family: $font-primary;
$custom-pp-stats-text-size: 20px;
$custom-pp-stats-text-size-md: 18px;
$custom-pp-stats-text-weight: 500;
$custom-pp-stats-text-color: white;

// Details
$custom-pp-details-font-family: $font-primary;
$custom-pp-details-text-size: 18px;
$custom-pp-details-text-size-md: 14px;
$custom-pp-details-text-weight: 500;
$custom-pp-details-text-color: white;

.user_mode .playerProfile > .reportTableElement{
  display: none;
}
.custom-player-profile{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: $color-primary;
  background-image: url(../images/flag_blue_background_player_profile.jpg);
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 650px) {
    flex-flow: column;
  }
}
.custom-pp-image{
  margin-bottom: auto;
  width: 100%;
  max-width: 258px;
  @media screen and (min-width: 401px) {
    height: 388px;
    flex: 1 1 258px;
  }

  img{
    width: 100% !important;
    height: 100% !important;
    display: block;
    object-fit: cover;
    object-position: center;
  }
}
.custom-pp-content{
  margin-bottom: auto;
  padding: 0 30px;
  flex: 1 1;
  @media screen and (min-width: $breakpoint-md-min) {
    padding: 0 0 0 40px;
  }
  @media screen and (max-width: $breakpoint-sm-max) {
    margin-top: 30px;
  }

}
.custom-pp-headline {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  @media screen and (min-width: $breakpoint-md-min) {
    flex-flow: row;
    min-height: 170px;
  }

}
.custom-pp-number{
  font-family: $custom-pp-number-font-family;
  font-size: $custom-pp-number-text-size;
  font-weight: $custom-pp-number-text-weight;
  color: $custom-pp-number-text-color;
  background-color: $color-secondary;
  line-height: 1.3;
  @media screen and (min-width: $breakpoint-md-min) {
    padding: 0 15px 0px 0px;
    margin-right: 35px;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      right: 100%;
      height: 100%;
      width: 40px;
      background-color: $color-secondary;
    }
  }
  @media screen and (max-width: $breakpoint-sm-max) {
    font-size: $custom-pp-number-text-size-md;
    padding: 0 15px;
    margin-bottom: 10px;
  }

}
.custom-pp-name{
  font-family: $custom-pp-name-font-family;
  font-size: $custom-pp-name-text-size;
  font-weight: $custom-pp-name-text-weight;
  color: $custom-pp-name-text-color;
  line-height: 1;
  @media screen and (max-width: $breakpoint-sm-max) {
    font-size: $custom-pp-name-text-size-md;
    text-align: center;
    margin-bottom: 30px;
  }
}
.custom-pp-stats{
  font-family: $custom-pp-stats-font-family;
  font-size: $custom-pp-stats-text-size;
  @media screen and (max-width: $breakpoint-sm-max) {
    font-size: $custom-pp-stats-text-size-md;
  }
  font-weight: $custom-pp-stats-text-weight;
  color: $custom-pp-stats-text-color;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 40px;

  @media screen and (min-width: $breakpoint-md-min) {
    li:not(:last-child) {
      &:after {
        content: "|";
        padding: 0 2ch;
      }
    }
  }
  @media screen and (min-width: 401px) and (max-width: $breakpoint-sm-max) {
    justify-content: center;
    li{
      padding: 0 1ch;
    }
  }
  @media screen and (max-width: 400px) {
    flex-flow: column;
    align-items: center;
    li{
      padding: 0 1ch;
    }
  }


}
.stat-wrap{
  overflow: hidden;
}

.custom-pp-details{
  font-family: $custom-pp-details-font-family;
  font-size: $custom-pp-details-text-size;
  font-weight: $custom-pp-details-text-weight;
  color: $custom-pp-details-text-color;
  list-style: none;
  @media screen and (min-width: $breakpoint-md-min){
    li{
      display: flex;
      flex-flow: row;
      + li{
        margin-top: .5em;
      }
      
    }
    .pp-label{
      width: 33%;
    }
    .pp-text{
      width: 66%;
    }
  }
  @media screen and (max-width: $breakpoint-sm-max) {
    font-size: $custom-pp-details-text-size-md;
    text-align: center;
    li + li{
      margin-top: .5em;
    }
  }

}

// <link href="//assets.ngin.com/site_files/3407/stage/_site/css/3407_style.css" type="text/css" rel="stylesheet" media="screen" />
// <script src="//assets.ngin.com/site_files/3407/stage/_site/js/3407_script.js"></script><link href="https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,700|Muli:400,400i,600,700,900"rel="stylesheet">
// gaa && git reset sites/3407/project.json
