.custom-text-block-container {
    border: 2px solid #fff;
    background: linear-gradient(to bottom, #f8f8f8, #d0d0d0);
    display: flex;
    justify-content: stretch;
    flex-flow: row;
    @media screen and (max-width: $breakpoint-sm-max) {
        display: block;
    }
    .column-1 {
        @media screen and (max-width: $breakpoint-sm-max) {
            border-top: 2px solid #fff;
        }
    }
    .column-2 {
        border-left: 2px solid #fff;
        @media screen and (max-width: $breakpoint-sm-max) {
            border-left: none;
            border-top: 2px solid #fff;
        }
    }
    .text-block-background {
        background-size: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.05;
        ~ .pageEl{
            position: relative;
        }
    }
    .textBlockElement {
        padding: 30px;
        h3 {
            text-transform: uppercase;
            position: relative;
            font-size: 30px;
            @media screen and (max-width: $breakpoint-sm-max) {
                text-align: center !important;
            }
            span {
                font-size: 30px;
                @media screen and (max-width: $breakpoint-sm-max){
                    font-size: 26px;
                  }
            }
        }
        p {
            font-family: $font-secondary;
            font-size: 14px;
            font-weight: 400;
            color: $color-black;
            @media screen and (max-width: $breakpoint-sm-max) {
                text-align: center;
                padding: 20px;
                margin-bottom: 0;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
        .rightTextImage,
        .leftTextImage {
            @media screen and (max-width: $breakpoint-sm-max) {
                display: none;
            }
        }
        img {
            z-index: 1;
            position: absolute;
        }
        .clearfix {
            padding-top: 20px;
        }
    }
    .custom-image-bottom {
        .textBlockElement {
            padding: 30px 30px 0 30px;
            margin-bottom: 0;
            @media screen and (max-width: $breakpoint-sm-max) {
                padding: 30px;
            }
            .rightTextImage,
            .leftTextImage {
                // margin: 5px 0 0 10px;
                margin: 0;
                @media screen and (max-width: $breakpoint-sm-max) {
                    display: none;
                }
                img {
                    width: 100% !important;
                    max-width: 285px;
                    transform: translateY(-34px);
                }
            }
        }
    }
}