.custom-network-menu-button{
  width: 34px;
  height: 34px;
  border: 1px solid white;
  padding: 10px 6px;
  cursor: pointer;
  display: block;
  background-image: url(../images/grid-menu.svg);
  background-position: center center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: background-color $transition-linear;
  div + &,
  + div{
    margin-left: 5px;
  }
  &:hover,
  &.is-active{
    background-color: rgba(black, .15);
  }
}


//

.custom-network-menu-container {
  display: none;
  .custom-network-menu-active & {
    display: block;
  }
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  max-width: $content-area-width;
  width: 100%;
  margin: auto;
  pointer-events: none;
  
  .custom-network-menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    margin: 0 0 0 auto;
    background-color: rgba(white, .95);
    pointer-events: auto;
  }

  .network-item {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    > a {
      display: block;
      width: 60px;
      height: 60px;
      flex: 0 0 60px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      object-position: center;
    }
    .cutline{
      margin: 0px;
      padding: 0px;
      padding-left: 10px;
      background-color: transparent;
      font-family: $font-primary;
      color: $color-gray-dk;
      font-size: 14px;
      font-style: normal;
      line-height: 1;
      display: block;
    }
  }
}

.custom-network-menu-overlay{
  display: none;
  .custom-network-menu-active &{
    display: block;
  }
  position: absolute;
  top: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  background-color: black;
  opacity: .25;
  z-index: 0;
}

.custom-progress {
  display: none;
}

.custom-network-is-loading {
  .custom-progress {
    display: block;
    margin: 10px auto 0;
    width: 70px;
    text-align: center;

    >span {
      width: 18px;
      height: 18px;
      // background-color: $primary-color;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;

      &:nth-child(1) {
        animation-delay: -0.32s;
      }

      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }
  }

  @keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
      background-color: $color-gray-dk;
      transform: scale(0);
    }

    40% {
      background-color: $color-primary;
      transform: scale(1.0);
    }
  }
}

.custom-network-is-unreachable {
  .custom-network-menu {
    &:after {
      content: "Content Unavailable.";
      text-align: center;
    }
  }
}


// Mobile Nav Network Menu

.has-mobile-nav{
  .theme-mobile-nav{
    .more-network-container {
      overflow-y: hidden;
      position: absolute;
      top: $mobile-nav-height;
      bottom: 0;
      right: 100%;
      transition: right .2s ease;
      background: white;
      display: flex;
      flex-flow: column;
      &.dropdown-open {
        right: 0;
      }
      .theme-nav-title{
        width: 100%;
      }
    }
    .more-network-items{
      overflow-y: auto;
    }
    .more-network-item {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      line-height: 1.4;
      padding: 15px 15px 0 15px;
      &:hover{
        color: $color-gray-dk;
      }
      &:last-of-type{
        padding-bottom: 20px;
      }
    }

    .more-network-item-image {
      flex: 0 0 35px;
      width: 35px;
      display: flex;
      align-items: center;
    }

    .more-network-item-image img {
      width: 100%;
      max-width: 100%;
      max-height: 35px;
    }

    .more-network-item-title {
      padding-left: 15px;
    }

  }
  &.custom-network-menu-mobile-active{
    .theme-mobile-nav .more-network-container{
      right: 0;
    }
  }
}
.theme-mobile-nav .mobile-nav-extra{
  > .theme-nav-link{
    color: white;
    background-color: $color-secondary;
    &:hover{
      background-color: $color-secondary-dk;
    }
  }
}