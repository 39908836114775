/* General element styles */
.pageElement.mediaSlider{
    padding-top:20px;
    border-top: 2px solid $borderColor;
    h2{
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.2;
      margin: 0 0 .1em 0;
      text-align: left;
      text-transform: none;
    }
  }
  
  .sn-media-slider, .sn-media-slider * { box-sizing: border-box; }
  .sn-media-slider {
    position: relative;
    padding: 0.05px 0; /* preven margin collapse */
    overflow:hidden;
  }
  /* Adjust vertical spacing for edit mode */
  .edit_mode .sn-media-slider { margin-top: 10px; margin-bottom: 10px; }
  
  .sn-media-slider .slider { background: #000; }
  
  /* make sure viewport has a height or nothing will show up */
  .sn-media-slider .flex-viewport:last-of-type { height: 100%; }
  
  .sn-media-slider .slides {
    position: absolute;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .sn-media-slider .slides .slide {
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .sn-media-slider .slides .slide .slide-overlay {
    color: #fff;
    position: absolute;
    display: table;
    height: 100%;
    width: 100%;
    padding: 5% 15%;
    overflow: hidden;
    z-index: 1;
  }
  
  .sn-media-slider .slides .slide .media-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }
  
  .sn-media-slider .slides .slide .media-wrapper img { display: block; }
  .sn-media-slider .slides .slide .media-wrapper img.full-height { width: auto; height: 100%; }
  .sn-media-slider .slides .slide .media-wrapper img.full-width { width: 100%; height: auto; }
  
  /* Direction nav */
  .sn-media-slider .flex-direction-nav {
    height: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    a{
      text-decoration: none;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: -25px 0 0;
      position: absolute;
      top: 50%;
      z-index: 10;
      overflow: hidden;
      opacity: 0;
      cursor: pointer;
      color: #444;
      text-align:center;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      &.flex-next{
        right: -40px; 
        padding:0;
        padding-left: .2em;
        &:before{
          content: '\f105';
        }
      }
      &.flex-prev{
        left: -40px;
        padding:0;
        padding-right: .2em;
      }
    }
  }
  
  .sn-media-slider:hover{
    .flex-direction-nav{
      .flex-prev { 
        opacity: 0.7; 
        left: 2%;
        &:hover{
          opacity: 1;
        } 
      }
      .flex-next { 
        opacity: 0.7; 
        right: 2%;
        &:hover{
          opacity:1;
        }
      }
    }
  }
  
  /* Pagination */
  .sn-media-slider .slider-pagination {
    position: absolute;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 3;
    height: 18px;
  }
  .sn-media-slider .slider-pagination .paging-item {
    cursor: pointer;
    height: 14px;
    width: 14px;
    background: $color-primary;
    display: inline-block;
    margin: 0 2px;
  }
  .sn-media-slider .slider-pagination .paging-item:hover { background: $color-secondary; }
  .sn-media-slider .slider-pagination .paging-item.flex-active { background: $color-secondary; }
  .sn-media-slider .slider-pagination .paging-item .page-number { display: none; }
  
  /* Pagination styles */
  .sn-media-slider .slider-pagination.circular .paging-item {
    border-radius: 100%;
  }
  .sn-media-slider .slider-pagination.dashed .paging-item { width: 28px; height: 50%; }
  
  /* Number pagination */
  .sn-media-slider .slider-pagination-numbers {
    position: absolute;
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 3;
  }
  
  .sn-media-slider .slider-pagination-numbers .paging-item { display: none; }
  .sn-media-slider .slider-pagination-numbers .paging-item.flex-active { display: inline; }
  
  /* Pagination position */
  .sn-media-slider .slider.top-left-paging,
  .sn-media-slider .slider.top-center-paging,
  .sn-media-slider .slider.top-right-paging { margin: 32px 0 0; }
  .sn-media-slider .slider.bottom-left-paging,
  .sn-media-slider .slider.bottom-center-paging,
  .sn-media-slider .slider.bottom-right-paging { margin: 0 0 32px; }
  .sn-media-slider .slider.top-left-paging .slider-pagination,
  .sn-media-slider .slider.top-left-paging .slider-pagination-numbers { top: 0; text-align: left; }
  .sn-media-slider .slider.top-right-paging .slider-pagination,
  .sn-media-slider .slider.top-right-paging .slider-pagination-numbers { top: 0; text-align: right; }
  .sn-media-slider .slider.top-center-paging .slider-pagination,
  .sn-media-slider .slider.top-center-paging .slider-pagination-numbers { top: 0; text-align: center; }
  .sn-media-slider .slider.bottom-left-paging .slider-pagination,
  .sn-media-slider .slider.bottom-left-paging .slider-pagination-numbers { bottom: 0; text-align: left; }
  .sn-media-slider .slider.bottom-right-paging .slider-pagination,
  .sn-media-slider .slider.bottom-right-paging .slider-pagination-numbers { bottom: 0; text-align: right; }
  .sn-media-slider .slider.bottom-center-paging .slider-pagination,
  .sn-media-slider .slider.bottom-center-paging .slider-pagination-numbers { bottom: 0; text-align: center; }
  
  .sn-media-slider .slider.top-left-paging .flex-direction-nav a,
  .sn-media-slider .slider.top-center-paging .flex-direction-nav a,
  .sn-media-slider .slider.top-right-paging .flex-direction-nav a {
    transform: translate(0, 16px);
  }
  .sn-media-slider .slider.bottom-left-paging .flex-direction-nav a,
  .sn-media-slider .slider.bottom-center-paging .flex-direction-nav a,
  .sn-media-slider .slider.bottom-right-paging .flex-direction-nav a {
    transform: translate(0, -16px);
  }
  
  /* Slide text */
  .sn-media-slider .slider .slide-text {
    display: table-cell;
    width: 100%;
    height: 100%;
  }
  .sn-media-slider .slider .slide-title {
    display: block;
    margin: 5% 0 2% 0;
    font-weight: bold;
    font-size: 250%;
    line-height: 130%;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0,0,0,.8);
    font-family: $font-primary;
  }
  .sn-media-slider .slider .slide-description {
    display: block;
    margin: 2% 0 5% 0;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0,0,0,.8);
    font-size: 130%;
    font-weight:400;
    line-height: 140%;
    font-family: $font-primary;
  }
  
  /* Slide text alignment */
  .sn-media-slider .slider .slide-text.top-left { text-align: left; }
  .sn-media-slider .slider .slide-text.top-right { text-align: right; }
  .sn-media-slider .slider .slide-text.top-center { text-align: center; }
  .sn-media-slider .slider .slide-text.middle-left { vertical-align: middle; text-align: left; }
  .sn-media-slider .slider .slide-text.middle-center { vertical-align: middle; text-align: center; }
  .sn-media-slider .slider .slide-text.middle-right { vertical-align: middle; text-align: right; }
  .sn-media-slider .slider .slide-text.bottom-left { vertical-align: bottom; text-align: left; }
  .sn-media-slider .slider .slide-text.bottom-center { vertical-align: bottom; text-align: center; }
  .sn-media-slider .slider .slide-text.bottom-right { vertical-align: bottom; text-align: right; }
  
  /* Size Modifications */
  .sn-media-slider .slider.sn-breakpoint-lt-500{
   .slide-title {
     font-size: 180%;
    }
    .slide-description { 
      font-size: 120%;
    }
  }
  
  .sn-media-slider .slider.sn-breakpoint-lt-300{
    .slide-title {
      font-size: 120%;
    }
    .slide-description {
      display: none;
    }
    .flex-direction-nav{
      a{
        width: 24px;
        height:24px;
        line-height:24px;
        &:before{
          font-size:20px;
        }
        &.flex-prev{
          opacity:0.9;
          padding-right:.125em;
        }
        &.flex-next{
          opacity:0.9;
          padding-left:.125em;
        }
      }
    }
  }
  
  .sn-media-slider:hover .slider.sn-breakpoint-lt-300{
    .flex-direction-nav{
      .flex-prev { 
        left: 1%;
      }
      .flex-next { 
        right: 1%;
      }
    }
  }
  
  
  /* Adjustments for Mobile Views */
  @media only screen and (max-width: 480px) {
    .sn-media-slider{
      .slider{
        .slide-title {
          font-size: 120%;
        }
        .slide-description{
          display: none;
        }
       .flex-direction-nav{
          a{
            width: 24px;
            height:24px;
            line-height:24px;
            &:before{
              font-size:20px;
            }
            &.flex-prev{
              opacity:0.9;
              padding-right:.125em;
            }
            &.flex-next{
              opacity:0.9;
              padding-left:.125em;
            }
          }
        }
      }
    }
    .sn-media-slider:hover{
      .flex-direction-nav{
        .flex-prev { 
          left: 1%;
        }
        .flex-next { 
          right: 1%;
        }
      }
    }
  }