.padding {
    $padding-desktop: 80px;
    $padding-mobile: 40px;
    padding-top: $padding-desktop;
    padding-bottom: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $padding-mobile;
      padding-bottom: $padding-mobile;
    }
    &-top {
      padding-top: $padding-desktop;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $padding-mobile;
      }
      &-mobile {
        @media screen and (max-width: $breakpoint-sm-max){
          padding-top: $padding-mobile;
        }
      }
    }
    &-bottom {
      padding-bottom: $padding-desktop;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $padding-mobile;
      }
      &-mobile {
        @media screen and (max-width: $breakpoint-sm-max){
          padding-bottom: $padding-mobile;
        }
      }
    }
  }

  .padding-md {
    $padding-desktop: 50px;
    $padding-mobile: 25px;
    padding-top: $padding-desktop;
    padding-bottom: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $padding-mobile;
      padding-bottom: $padding-mobile;
    }
    &-top {
      padding-top: $padding-desktop;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $padding-mobile;
      }
      &-mobile {
        @media screen and (max-width: $breakpoint-sm-max){
          padding-top: $padding-mobile;
        }
      }
    }
    &-bottom {
      padding-bottom: $padding-desktop;
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $padding-mobile;
      }
      &-mobile {
        @media screen and (max-width: $breakpoint-sm-max){
          padding-bottom: $padding-mobile;
        }
      }
    }
  }
  
  .compact {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
      margin-top: 0;
    }
    &-top {
      &.pageEl .pageElement,
      .pageEl .pageElement {
        margin-top: 0;
      }
    }
    &-bottom {
      &.pageEl .pageElement,
      .pageEl .pageElement {
        margin-bottom: 0;
      }
    }
  }
  .padding-collapse-x{
    &.layoutContainer,
    > .column{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .padding-collapse-y{
    &.layoutContainer,
    > .column{
      padding-top: 0;
      padding-bottom: 0;
    }
  }

// Custom backgrounds
.background-primary {
  background-color: $color-primary;
}
.background-white {
  background-color: #ffffff;
}
.background-gradient {
  background: linear-gradient(171deg, #f8f8f8 0%, #D0D0D0 100%);
}
.background-snow {
  background-image:url('../images/usa_snow_background.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.background-usa-flag {
  background-image: url('../images/flag_gradient_background.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .connect  {
    background: transparent;
  }
}

// Custom link elements
.custom-link-secondary {
  .linkElement,
  .textBlockElement .text{
    a {
      @include global-button-container($color-secondary, 4px);
      @include global-button-text();
    }
  }
  &.center {
    text-align: center;
  }
}
.blueButton{
  .linkElement,
  .textBlockElement .text{
    a {
      @include global-button-container($color-primary, 4px);
      @include global-button-text();
    }
  }
  &.center {
    text-align: center;
  }
}

// Custom hero text under tab element
.custom-text-hero {
  h2 {
      color: $color-black;
      font-size: 30px;
      font-weight: 900;
      margin-bottom: 10px;
  }
  p {
      max-width: 754px;
      width: 100%;
      margin: 0 auto;
      line-height: 2em;
  }
}

// Utility class to hide element on mobile
.custom-mobile-toggle {
  @media screen and (max-width: $breakpoint-sm-max) {
    display: none;
  }
}

// Utility class for styling h3
.custom-header-style {
  h3 {
    font-size: 24px;
    color: $color-primary;
    position: relative;
    @media screen and (max-width: $breakpoint-sm-max) {
      text-align: center;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      height: 3px;
      width: 40px;
      background-color: $color-primary;
      @media screen and (max-width: $breakpoint-sm-max){
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

@mixin custom-header ($text-color: $color-primary, $text-size: 24px){
   
    .pageElement > h3:first-child {
      font-size: $text-size;
      color: $text-color;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        height: 3px;
        width: 40px;
        background-color: $color-primary;
      }
    }
  
}

.custom-header {
  @include custom-header;
  &.center {
    .pageElement > h3:first-child {
      text-align: center !important;
      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &.center-mobile {
    @media screen and (max-width: $breakpoint-sm-max){
      .pageElement > h3:first-child {
        text-align: center !important;
        &:after {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
  &.text-color-secondary {
    .pageElement > h3:first-child {
      color: $color-secondary;
    }
  }
}

// Custom sticky ad
.has-main-nav{
  .user_mode{
    .sticky-ad{
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999999;
      background: #FFF;
      transform: translateY(0%);
      transition: transform .2s .5s linear; // $transition-linear;
      &.is-not-stuck{
        transform: translateY(100%);
      }
    }
  }
  &.nav-fixed .user_mode .sticky-ad { transform: translateY(100%); }
  &.page-manager-visible .user_mode .sticky-ad { left: 60px; }
}