#splash{
    &.user_mode {
        #topNav, #topNavPlaceholder,
        #displayBodyHeader, .snFooterContainer {
            display: none!important;
        }
        .site-background {
            background-image: url('../images/flag_gradient_background.jpg');
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            top: 0 !important;
        }
        #panelTwo {
            padding:0!important;
            &:before {
                background-color: transparent;
                box-shadow: none;
            }
        }
        #accountNav {
            top: 0!important;
        }
        .layoutContainer {
            background-color: transparent;
        }
        .custom-image {
            img {
                padding: 75px;
            }
        }
        .custom-splash {
            .hrElement {
                height: 1px;
                background: #fff;
            }
            h3 {
                padding: 20px;
                text-transform: uppercase;
            }
            p {
                color: #fff;
                font-weight: 700;
                width: 75%;
                margin: 0 auto;
                padding: 10px;
            }
            .sn-social-media-list {
                text-align: center;
                padding: 10px;
                .sn-social-media-icon {
                    background-color: $link-color;
                    transition: $transition-standard;
                    &:hover {
                        background-color: $link-color-darken-5 !important;
                    }
                }
            }
        }
    }
}