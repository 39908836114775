.sn-social-media-list {
    .center & {
        display: flex;
        justify-content: center;
    }
    .center-mobile & {
        @media screen and (max-width: $breakpoint-sm-max){
            display: flex;
            justify-content: center;
        }
    }
    .center-desktop & {
        @media screen and (min-width: $breakpoint-md-min){
            display: flex;
            justify-content: center;
        }
    }
    .sn-social-media-icon {
        background-color: $color-secondary;
        transition: background-color $transition-standard;
        &:hover {
            background-color: $color-secondary-dk !important;
            opacity: 1;
        }
    }
}