// Scoreboard
#GameShowScoreboardSimple {
  margin-bottom: 15px;

  .game-header { margin-bottom: 15px; }

  .game_info_bar_v2 .status { float: left; }
}

#GameShowContent.game_show_nextgen {
  margin: auto !important;
}
