// Global Footer
// Edit mode UI
.custom-footer-extra{
  @include edit_ui_element($padding: 10px);
  &:empty{ display: none; }
}

$custom-footer-band-background-color: $color-primary;
$custom-footer-text-color: white;
$custom-footer-text-size: 12px;
$custom-footer-text-font-family: $font-secondary;
$custom-footer-text-weight: 500;

$custom-footer-header-text-color: white;
$custom-footer-header-text-size: 12px;
$custom-footer-header-text-font-family: $font-secondary;
$custom-footer-header-text-weight: 900;
$custom-footer-header-text-case: uppercase;

$custom-footer-link-color: white;
$custom-footer-link-color-hover: white;
$custom-footer-link-size: 12px;
$custom-footer-link-font-family: $font-secondary;
$custom-footer-link-weight: 500;

$custom-footer-social-background-color: white;
$custom-footer-social-background-color-hover: white;
$custom-footer-social-text-color: $color-primary;

.snFooterContainer{
  background-color: $custom-footer-band-background-color;
}
.custom-footer-extra{
  @include content-area-width;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 20px;

  [class*="custom-footer-col-"]{
    width: 20%;
    display: flex;
    flex-flow: row wrap;
    padding: 0 10px;
    > div{
      width: 100%;
    }
    .partner-logo{
      width: auto;
      max-width: 35px;
      margin: 0 10px;
      img{
        width: 100%;
        max-width: 100%;
      }
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      width: auto;
      flex: 1 1 200px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .custom-footer-row{
    padding: 0 10px;
    width: 100%;
  }

  .textBlockElement > h3{
    color: $custom-footer-header-text-color;
    font-size: $custom-footer-header-text-size;
    font-family: $custom-footer-header-text-font-family;
    font-weight: $custom-footer-header-text-weight;
    text-transform: $custom-footer-header-text-case;
    margin-bottom: 1em;
  }
  .text{
    color: $custom-footer-text-color;
    font-size: $custom-footer-text-size;
    font-family: $custom-footer-text-font-family;
    font-weight: $custom-footer-text-weight;
    ul{
      list-style: none;
      margin: 0;
      li{
        margin-bottom: 1em;
      }
    }
    h1, h2, h3, h4, h5, h6{
      color: $custom-footer-header-text-color;
    }
    a{
      color: $custom-footer-link-color;
      font-size: $custom-footer-link-size;
      font-family: $custom-footer-link-font-family;
      font-weight: $custom-footer-link-weight;
      &:hover{
        color: $custom-footer-link-color-hover;
        text-decoration: underline;
      }
    }
  }
  .footer-site-logo{
    .heroPhotoElement{
      max-width: 145px;
      @media screen and (max-width: $breakpoint-sm-max) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    margin-bottom: 45px;
  }
  .sn-social-media-list{
    display: flex;
    flex-flow: row wrap;
    &:hover {
      .sn-social-media-icon {
        opacity: 0.4;
      }
    }
    .sn-social-media-icon{
      color: $custom-footer-social-text-color;
      background-color: $custom-footer-social-background-color;
      transition: background-color $transition-linear, opacity $transition-linear;
      &:hover{
        background-color: $custom-footer-social-background-color-hover !important;
        opacity: 1;
      }
    }
  }
}


