.custom-directory-parent{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  > div{
    width: 100%;
  }
}
.custom-directory-parent .custom-directory-neighbor {
  @media screen and (min-width: $breakpoint-md-min) {
    width: calc(#{((100% / 3) * 2)} - #{$desktop-padding});
  }
}
.custom-directory{
  width: 100%;
  @include page-element-margin;
  list-style: none;
  @media screen and (min-width: $breakpoint-md-min){
    width: calc(#{(100% / 3)} - #{$desktop-padding});
  }
}
.custom-directory-item{
  + .custom-directory-item {
    margin-top: 2px;
  }
  a{
    @include global-link-style;
    @include global-link-container;
    @include global-link-text;
    @include global-link-icon-style;
    @include global-link-icon-character;
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 17px;
    &:after{
      font-size: 21px;
    }
    &:hover{
      color: $color-primary;
    }
  }
  &.disabled,
  &.private {
    display: none;
  }
}